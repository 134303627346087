.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: justify;
    min-height: 80vh;
    /* background-color: #f5f5f5; */
}

.about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.about-container p,
.about-container ul {
    text-align: justify;
    max-width: 800px;
    margin: 0 auto 20px auto;
    color: #666;
}

.about-container h2 {
    font-size: 2em;
    color: #333;
    margin-top: 30px;
    margin-bottom: 20px;
}

.about-container ul {
    list-style-type: none;
    padding: 0;
}

.about-container ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
}
