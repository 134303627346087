.footer {
    background-color: #000000;
    color: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    margin: 10px;
    flex: 1;
    min-width: 200px;
}

.footer-section h4 {
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 8px;
}

.footer-section ul li a {
    color: #ffffff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-links a {
    color: #ffffff;
    margin-right: 10px;
    font-size: 1.5em;
}

.social-links a:hover {
    color: #bbbbbb;
}