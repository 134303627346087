.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
}

.navbar-logo img {
    height: 100px;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    font-size: 1.5rem;
}

.navbar-links li a {
    text-decoration: none;
    color: #333;
}

.close-menu-icon {
    display: none;
}

.menu-icon {
    display: none;
    font-size: 2rem;
    cursor: pointer;
}


@media (max-width: 768px) {
    
    .navbar-logo img {
        height: 60px;
    }

        .navbar-links {
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            width: 100%;
            background-color: #fff;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;
            font-size: 1.3rem;
        }
    
        .navbar-links.active {
            transform: translateX(0);
        }
    
        .menu-icon {
            display: block;
        }
    
        .close-menu-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 2rem;
            cursor: pointer;
            display: block;
        }
                .no-scroll {
                    overflow: hidden;
                }
}