.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    text-align: center;
    padding: 5%;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    flex-direction: row;

}

.text-content {
    max-width: auto;
    text-align: center;
}

.applogo {
    width: 600px;
}

.iphone-image {
    width: 600px;
}

.header-content h1 {
    font-size: 3em;
    margin: 20px 0;
}

.header-content p {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.header-content button {
    padding: 10px 20px;
    font-size: 1.5em;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: black;
    border-radius: 20px;
    width: 200px;
    height: 3rem;
    font-weight: bold;
}

@media (max-width: 1400px) {
    .header {
            height: auto;
        }
    .header-content {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .iphone-image {
        width: 300px;
        right: 12%;
        padding-bottom: 10%;
        margin-right: 25%;
    }

    .applogo {
        width: 300px;
    }

    .text-content {
        text-align: center;
    }

        .header-content button {
            font-size: 1.2em;
        }
}