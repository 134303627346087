.main-content {
    padding: 30px;
    width: auto;
    text-align: center;
    background-color: rgb(227, 227, 227);
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* Espace entre le logo et le QR code */
    margin-bottom: 20px;
}

.app-logo {
    width: 200px;
    height: auto;
    margin: 0;
}

.qr-code {
    width: 150px;
}

.download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.download-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: black;
    border-radius: 20px;
    width: auto;
    height: auto;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: rgb(70, 70, 70);
}

.download-button img {
    width: 40px;
    height: auto;
    margin-right: 15px;
}

.download-button-text {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.download-button .small-text {
    font-weight: bold;
    font-size: 1em;
}

.download-button .large-text {
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 2px;
}

@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .download-buttons {
        flex-direction: column;
        align-items: center;
    }

    .download-button {
        width: 185px;
    }

    .download-button img {
        width: 25px;
    }
}